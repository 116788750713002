document.addEventListener('DOMContentLoaded', function () {

    // sticky header
    const header = document.getElementById("header-sticky");

    document.addEventListener('scroll', function () {
        if (window.scrollY >= 300) {
            header.classList.add('header__sticky');
        } else {
            header.classList.remove('header__sticky');
        }
    });


    // Materializecss
    const tabs = document.getElementById('shoptabs'),
        sidenav = document.querySelectorAll('.sidenav'),
        modal = document.querySelectorAll('.modal');

    M.Tabs.init(tabs);
    M.Sidenav.init(sidenav);
    M.Modal.init(modal);

    // SwiperJS
    const slider = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        observer: true,
        observeParents: true,
        parallax: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },

        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 2,
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 2,
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },
            },
        }
    });

    // Hide video cover
    const videoWrap = document.querySelector(".video__wrap"),
        videoCover = document.querySelector(".video__cover"),
        videoItem = document.querySelector("video");

    function hideVideoCover() {
        videoCover.style.display = "none";
        videoItem.play();
    }

    videoWrap.addEventListener('click', hideVideoCover);


});
